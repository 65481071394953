import styled from '@emotion/styled'

export default styled('div')`
  width: calc(100% - ${props => props.left}px);
  z-index: 1;
  position: fixed;
  max-height: 30%;
  bottom: 0;
  transform: translateY(${props => props.show ? '0' : '100%'});
  transition: transform 0.25s;
  left: ${props => props.left}px;
  padding: 18px 24px 30px;
  text-align: center;
  color: var(--white-color);
  background-color: var(--${props => props.color === 'success' ? 'success-color' : 'primary-color' });
  display: block;
`
