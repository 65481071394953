import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { StyledRow, StyledColumn } from './style';

const Grid = forwardRef(({children, container, spacing, xs, sm, ...props}, ref) => {
  return (
    container ? (
      <StyledRow spacing={spacing} {...props} ref={ref}>{children}</StyledRow>
    ) : (
      <StyledColumn xs={xs} sm={sm} {...props} ref={ref}>{children}</StyledColumn>
    )
  )
})

const gridList = Array.from({length: 12}, (_, index) => index + 1);

Grid.propTypes = {
  xs: PropTypes.oneOf(gridList),
  sm: PropTypes.oneOf(gridList),
  spacing: PropTypes.number.isRequired,
  container: PropTypes.bool
};

Grid.defaultProps = {
  xs: 12,
  sm: 12,
  spacing: 0
};

export default Grid;
