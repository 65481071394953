import styled from '@emotion/styled';
import { responsive } from 'themes/style';

const StyledRow = styled('div')(props => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexFlow: 'row wrap',
  width: `calc(100% + ${props.theme.spacing(props.spacing)}px)`,
  marginLeft: -props.theme.spacing(props.spacing),
  '> div': {
    paddingLeft: props.theme.spacing(props.spacing)
  }
}))

const StyledColumn = styled('div')(props => ({
  width: `${props.theme.flex(props.sm)}%`,
  [`${responsive('max', 'smartphone')}`]: {
    width: `${props.theme.flex(props.xs)}%`,
  },
  display: props.display || 'block'
}))

export {
  StyledRow,
  StyledColumn
}
