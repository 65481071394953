import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import { responseStatus } from 'config/constants';
import { showAlert } from 'reducers/alertReducer';
import { getErrorMessage } from 'utils/common';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  headers: {
    'content-type': 'application/json'
  },
  prepareHeaders: (headers, { getState, endpoint }) => {
    if (endpoint === 'uploadToS3') return headers;
    const basename = window.location.pathname.indexOf('/admin') !== -1 ? 'admin' : 'front';
    const token = localStorage.getItem(basename === 'front' ? 'userToken' : 'adminToken');
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }

    return headers
  },
});

export const customBaseQuery = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  if (result.error) {
    switch (result.error.status) {
    case responseStatus.UNAUTHORITY:
      api.dispatch({ type: 'RESET_AUTH' });
      setTimeout(() => {
        api.dispatch({ type: 'HIDE_LOADING' })
        api.dispatch(
          showAlert({
            type: 'error',
            message: getErrorMessage(result?.error?.error || result?.error?.data?.message),
          })
        )
      }, 500);
      break;
    case responseStatus.NOT_FOUND:
      api.dispatch({ type: 'REDIRECT_NOT_FOUND' })
      api.dispatch({ type: 'HIDE_LOADING' })
      break;
    default:
      api.dispatch(
        showAlert({
          type: 'error',
          message: getErrorMessage(result?.error?.error || result?.error?.data?.message),
        })
      )
      api.dispatch({ type: 'HIDE_LOADING' })
      break;
    }
  }
  api.dispatch({ type: 'RESET_REDIRECT_NOT_FOUND' })
  return result;
}