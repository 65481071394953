import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from './baseQuery';

export const authApi = createApi({
  baseQuery: customBaseQuery,
  reducerPath: 'authApi',
  tagTypes: ['Auth'],
  endpoints: (builder) => ({
    oAuth: builder.mutation({
      query: (params) => ({
        url: 'oauth/token',
        method: 'POST',
        body: {
          ...params,
          grant_type: 'password',
          account_type: 'User'
        }
      })
    }),
    sendEmail: builder.mutation({
      query: (params) => ({
        url: 'reset_passwords',
        method: 'POST',
        params: params
      })
    }),
    resetPassword: builder.mutation({
      query: (payload) => ({
        url: 'reset_passwords',
        method: 'PUT',
        params: payload.params,
        body: payload.body
      })
    }),
    sendCode: builder.mutation({
      query: (body) => ({
        url: 'auth/confirm_code',
        method: 'POST',
        body,
      }),
    }),
    sendCodePhone: builder.mutation({
      query: (body) => ({
        url: 'auth/confirm_code_phone',
        method: 'POST',
        body,
      }),
    }),
    authLogin: builder.mutation({
      query: (body) => ({
        url: 'auth/verify',
        method: 'POST',
        body,
      }),
    }),
    user: builder.query({
      query: () => ({
        url: 'user',
        method: 'GET',
      })
    }),
    updateUser: builder.mutation({
      query: (body) => ({
        url: 'user',
        method: 'PUT',
        body,
      })
    }),
    changePassword: builder.mutation({
      query: (body) => ({
        url: 'passwords',
        method: 'PUT',
        body
      })
    }),
  }),
  
});

export const { 
  useSendCodeMutation,
  useAuthLoginMutation,
  useSendCodePhoneMutation,
  usePrefetch,
  useUserQuery,
  useUpdateUserMutation,
  useLazyUserQuery,
  useOAuthMutation,
  useSendEmailMutation,
  useResetPasswordMutation,
  useChangePasswordMutation
} = authApi;
