const routeName = {
  notFound: '/404',

  home: '/',
  notification: '/notification',
  myPage: '/my-page',
  changePassword: '/change-password',
  guide: '/about',
  videoCategories: '/categories',
  videoList: '/categories/:categoryName/videos',
  videoDetail: '/categories/:categoryName/videos/:videoName',
  trainingReport: '/training-report',
  bulletin: '/',
  termOfUse: '/terms',
  policy: '/policy',
  login: '/login',
  loginByEmail: '/login-by-email',
  otp: '/otp-verification',
  mainMenu: '/',
  questions: '/questions',
  questionPost: '/questions/post',
  questionEdit: '/questions/:questionId/edit',
  questionCategories: '/questions/categories/:categoryId',
  questionDetail: '/questions/:questionId',
  favoriteQuestions: '/my-page/favorite-questions',
  userQuestions: '/my-page/posted-questions',
  userResetPassword: '/password_setting',
  training: '/video-training',

  // company
  loginCompany: '/login',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  userList: '/users',
  userDetail: '/users/:id',
  userAdd: '/users/new',
  userEdit: '/users/:id/edit',
  requiredTraining: '/required-training',
  requiredTrainingListUser: '/required-training/list-users',
  companyInfo: '/company-info',
  companyNotification: '/company-notification',
  companyUserList: '/company_users',
  companyUserDetail: '/company_users/:id',
  companyUserAdd: '/company_users/new',
  companyUserEdit: '/company_users/:id/edit',
  branchAdd: '/branches/new',
  branchEdit: '/branches/:id/edit',
  trainingReportCompany: '/training_progresses',
  trainingReportDetail: '/training_progresses/:id',
  trainingCategoryList: '/training_categories',
  trainingCategoryDetail: '/training_categories/:id',
  trainingCategoryAdd: '/training_categories/new',
  trainingCategoryEdit: '/training_categories/:id/edit',
  trainingList: '/training_categories/:categoryId/trainings',
  trainingDetail: '/training_categories/:categoryId/trainings/:id',
  systemNotification: '/system-notifications'
}
export default routeName;
