import styled from '@emotion/styled';

const StyledFormItem = styled.div(props => ({
  marginBottom: props?.bottom || props.theme.spacing(6),
}));

const StyledFormError = styled.p(props => ({
  color: 'var(--error-color)',
  fontSize: 14,
  lineHeight: '20px',
  marginTop: props.theme.spacing(2),
}));

export {
  StyledFormItem,
  StyledFormError
}