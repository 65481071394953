import { forwardRef } from 'react';
import BaseIconStyled from '../style';

const MainIcon = forwardRef(({ children, color, variant, ...props }, ref) => {
  return (
    <BaseIconStyled {...props} color={color}>
      <path d="M8.66667 22H4C2.89543 22 2 21.1046 2 20V9.18713C2 8.48777 2.36531 7.83921 2.9634 7.47673L10.9634 2.62824C11.6005 2.24211 12.3995 2.24211 13.0366 2.62824L21.0366 7.47673C21.6347 7.83921 22 8.48777 22 9.18713V20C22 21.1046 21.1046 22 20 22H15.3333M8.66667 22V16.2222C8.66667 15.1177 9.5621 14.2222 10.6667 14.2222H13.3333C14.4379 14.2222 15.3333 15.1177 15.3333 16.2222V22M8.66667 22H15.3333" />
    </BaseIconStyled>
  );
});

export default MainIcon;
