import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const TabPanel = forwardRef(({children, value, index, ...props}, ref) => {
  return (
    <div hidden={value !== index} {...props} ref={ref}>
      {value === index && children}
    </div>
  )
})

TabPanel.propTypes = {
  value: PropTypes.any.isRequired,
  index: PropTypes.any.isRequired
};

export default TabPanel;
