import React, { forwardRef, useState } from 'react';
import TrainingIcon from '../../../assets/images/trainingIcon.svg';

const getResponsiveMargins = () => {
  if (window.innerWidth <= 600) {
    return { marginLeft: '-4px', marginRight: '6px' };
  }
};

const QuestionIcon = forwardRef(({ height, width, marginBottom }) => {
  const [margins, setMargins] = useState(getResponsiveMargins);

  React.useEffect(() => {
    const handleResize = () => setMargins(getResponsiveMargins);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <img src={TrainingIcon} height={height || 20} width={width || 20} style={{ marginBottom, ...margins }} alt='iconTraining'/>
  );
});

export default QuestionIcon;
