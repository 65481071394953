import { forwardRef } from 'react';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  tag: {
    padding: '4px 10px',
    borderRadius: 4,
    backgroundColor: 'var(--bg-light-cyan-color)',
    color: '#0CA1AA',
    fontSize: 14,
  },
});

const Tag = forwardRef(
  (props, ref) => {
    const styles = useStyles();
    const { children } = props;
    return (
      <span ref={ref} className={styles.tag} {...props}>
        {children}
      </span>
    );
  }
);

Tag.displayName = 'tag';
Tag.propTypes = {
  variant: PropTypes.oneOf(['outlined', 'contained']),
};

export default Tag;
