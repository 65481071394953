import { Footer, Header, Wrapper, Alert, Loading } from 'components';
import { Outlet } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react'
import { Theme } from './style.js';

function Default() {
  return (
    <ThemeProvider theme={Theme}>
      <Header />
      <Wrapper bg="background">
        <Outlet />
      </Wrapper>
      <Alert />
      <Loading />
      <Footer />
    </ThemeProvider>
  );
}

export default Default;
