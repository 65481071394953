import { forwardRef } from 'react';
import BaseIconStyled from '../style';

const NotificationIcon = forwardRef(({ children, color, variant, ...props }, ref) => {
  return (
    <BaseIconStyled {...props} color={color}>
      <path d="M9.259,22.565c2.109,2.141-0.529,2.599-0.529,2.599c-0.858,0.291-1.012-0.612-1.012-0.612 l-1.802-5.33C5.533,17.846,4.679,18.1,4.679,18.1c-2.169,0.734-2.96-1.755-2.96-1.755l-0.998-2.948 c-0.725-2.143,1.457-2.987,1.457-2.987L4.98,9.461c0.905-0.306,1.042,0.549,1.042,0.549l2.258,6.823 c0.193,0.569-0.193,1.813-0.193,1.813C8.087,20.414,9.872,21.278,9.259,22.565z M19.732,10.782c0.624-0.667,0.88-1.644,0.566-2.57 c-0.314-0.929-1.109-1.547-2.012-1.698l-2.104-6.221c-0.275-0.813-0.657,0.324-0.657,0.324c-1.547,6.164-8.411,8.032-8.411,8.032 c-0.795,0.271-0.44,0.906-0.44,0.906l1.988,5.874c0.229,0.678,0.939,0.438,0.939,0.438c7.521-2.546,11.691,1.342,11.691,1.342 s0.841,0.538,0.54-0.217L19.732,10.782z M22.406,3.297l-0.379-0.274l-2.126,2.921l0.378,0.275L22.406,3.297z M21.152,10.681 l3.162,1.746l0.227-0.411l-3.162-1.745L21.152,10.681z M21.178,8.286l3.471-1.003l-0.131-0.451l-3.471,1.003L21.178,8.286z"/>
    </BaseIconStyled>
  );
});

export default NotificationIcon;
