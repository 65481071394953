import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from './baseQuery';

export const uploadApi = createApi({
  baseQuery: customBaseQuery,
  reducerPath: 'uploadApi',
  tagTypes: ['upload'],
  endpoints: (builder) => ({
    createPreSignedUrl: builder.mutation({
      query: (body) => ({
        url: '/presigned_url',
        method: 'POST',
        body,
      }),
    }),
    uploadToS3: builder.mutation({
      query: ({ headers, url, body}) => ({
        headers,
        url,
        method: 'PUT',
        body,
      }),
    }),
  })
});

export const {
  useCreatePreSignedUrlMutation,
  useUploadToS3Mutation
} = uploadApi

