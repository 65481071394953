import styled from '@emotion/styled';

const StyledInput = styled.input((props) => ({
  width: '100%',
  height: '62px',
  backgroundColor: props.background ? 'var(--background-color)' : 'var(--white-color)',
  border: '1px solid var(--border-color)',
  boxSizing: 'border-box',
  borderRadius: '31.5px',
  fontSize: 18,
  lineHeight: '26px',
  padding: props.padding || '0 20px',
  '&::placeholder': {
    color: 'var(--placeholder-color)',
  }
}))

export default StyledInput;
