import { Box, Button, Container, Input, Typography, Link } from 'components';
import React, { useEffect, useRef, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCustomNav } from 'apps/front/routes/navigate';
import { useSendCodeMutation } from 'services/auth';
import RouteName from 'config/routes';
import useInputFilter from 'hooks/useInputFilter';
import useTitle from 'hooks/useTitle';

function LoginSP(props) {
  const { t } = useTranslation();
  useTitle(t('common:pageTitle:login'));
  const [sendCode] = useSendCodeMutation();
  const navigate = useCustomNav();
  const [loading, setLoading] = useState(false);
  const [errorMessage] = useState();
  const phoneRef = useRef(null);
  useInputFilter(phoneRef, /^[\d+]*$/);

  const {
    register,
    handleSubmit,
    setValue,
    control,

  } = useForm({
    mode: 'onSubmit',
  });



  useEffect(() => {
    register('phone');
  }, [register]);

  const phone = useWatch({
    control,
    name: 'phone'
  });

  const onSubmit = (data) => {
    let phone = data.phone;
    if (phone.startsWith('+')) {
      phone = phone.replace(/\+/, '%2B');
    }
    setLoading(true);
    sendCode({ phone_number: data.phone })
      .unwrap()
      .then((payload) => {
        // navigate otp verify
        navigate.pushName({
          path: RouteName.otp,
          replace: true,
          state: {
            phone: data.phone,
          },
        });
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      })
  };

  return (
    <Container bottom={80}>
      <Box pt={48} pb={80}>
        <Typography variant="h2">{t('login:title')}</Typography>
      </Box>
      <Box mb={12} display={'flex'} flexDirection={'row'}>
        <Typography variant="title2">{t('login:text:verifyPhone')}</Typography>
        <Typography color='placeholder' variant='caption' style={{ alignSelf: 'flex-end' }}>（{t('login:text:phoneCaption')}）</Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          type="tel"
          placeholder="0900000000"
          name="phone"
          ref={phoneRef}
          onChange={e => setValue('phone', e.target.value)}
        ></Input>
        {errorMessage && <Box mt={8}>
          <Typography variant="caption" color="error">{errorMessage}</Typography>
        </Box>}
        <Box maxWidth={400} margin="48px auto 0">
          <Button variant="contained" disabled={loading || !phone} fullWidth type="submit">
            {t('login:buttonTitle:smsVerify')}
          </Button>
        </Box>
        <Box pt={24} textAlign="center">
          <Link to={RouteName.loginByEmail} underline="always">
            {t('login:byEmail')}
          </Link>
        </Box>
      </form>
    </Container>
  );
}

export default LoginSP;
