import { createReducer, createAction } from '@reduxjs/toolkit';

export const showAlert = createAction('SHOW_ALERT');
export const hideAlert = createAction('HIDE_ALERT');
const initialState = {
  alert: {
    show: false,
    type: null,
    message: null,
    duration: 3000,
  },
};

const alertReducer = createReducer(initialState, (buidler) => {
  buidler
    .addCase(showAlert, (state, { payload: { type, message, duration = 3000 } }) => {
      state.alert = {
        show: true,
        type,
        message: Array.isArray(message) ? message[0] : message,
        duration
      }
    })
    .addCase(hideAlert, (state, {  payload: { type, duration = 3000 } }) => {
      state.alert = {
        show: false,
        type,
        message: null,
        duration,
      }
    })
});

export default alertReducer;
