import React from 'react';
import PropTypes from 'prop-types';
import LinkStyle from './style';
import { useNavigate } from 'react-router-dom';

const Link = React.forwardRef(({ children, to, state, color, underline, ...props }, ref) => {
  const navigate = useNavigate();
  const handleClick = () => {
    if (to) {
      navigate(to, { state: state});
    } else {
      return;
    }
  }

  return (
    <LinkStyle className={`link-${color} link-${underline}`} ref={ref} onClick={handleClick} {...props}>{children}</LinkStyle>
  )
})

Link.propTypes = {
  color: PropTypes.string,
  to: PropTypes.string.isRequired,
  state: PropTypes.object,
  underline: PropTypes.string
};

Link.defaultProps = {
  color: 'default',
  underline: 'always'
}

export default Link;
