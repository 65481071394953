import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
// import { Link } from 'react-router-dom';
import RouteName from 'config/routes';
import { StyledFooter, MenuItem, Menu } from './style';
import { Box, Link, Typography } from 'components';
import LogoSPIcon from 'components/Icon/LogoSPIcon';
import { useSelector } from 'react-redux';
import userSelector from 'selectors/userSelector';

const Footer = forwardRef(({children, ...props}, ref) => {
  const { t } = useTranslation();
  const user = useSelector(userSelector);
  return (
    <StyledFooter {...props}>
      <Box marginBottom={28}>
        <LogoSPIcon width={260} height={40} />
      </Box>
      <Menu>
        {user && <MenuItem><Link to={RouteName.myPage} underline='none'>{t('common:link:myPage')}</Link></MenuItem>}
        <MenuItem><Link to={RouteName.guide} underline='none'>{t('common:link:guide')}</Link></MenuItem>
        <MenuItem><Link to={RouteName.videoCategories} underline='none'>{t('common:link:video')}</Link></MenuItem>
        <MenuItem><Link to={RouteName.questions} underline='none'>{t('common:link:bulletin')}</Link></MenuItem>
        <MenuItem><Link to={RouteName.termOfUse} underline='none'>{t('common:link:termOfUse')}</Link></MenuItem>
        <MenuItem><Link to={RouteName.policy} underline='none'>{t('common:link:policy')}</Link></MenuItem>
      </Menu>
      <Box>
        <Typography variant="caption2" color="placeholder">{t('common:copyRight')}</Typography>
      </Box>
    </StyledFooter>
  );
});

export default Footer;
