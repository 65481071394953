import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const compileStrings = ctx => {
  const compiled = { resources: {}, ns: [] };
  // This regex does not work for en-US, en-UK, etc.
  const fileRegex = /(?<locale>\w*)\/(?<context>\w+)\.json$/;
  ctx.keys().forEach(file => {
    const { context, locale } = file.match(fileRegex).groups;
    compiled.resources[locale] = !!compiled.resources[locale] ? compiled.resources[locale] : {};
    compiled.resources[locale][context] = ctx(file);
  });
  
  // Filter unique namespaces
  compiled.ns = compiled.ns.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });
  
  return compiled;
};

const strings = compileStrings(require.context('./locales', true, /\w+\.json$/));

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: strings.resources,
    lng: 'jp',
    fallbackLng: 'jp',
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;