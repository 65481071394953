import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import StyledIconButton from './style';

const IconButton = forwardRef(({children, color, variant, ...props}, ref) => {
  return (
    <StyledIconButton className={`btn-${color} btn-${variant}`} {...props} ref={ref}>
      {children}
    </StyledIconButton>
  );
});

IconButton.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
};

IconButton.defaultProps = {
  color: 'primary',
  variant: 'default'
};

export default IconButton;
