import { forwardRef } from 'react';
import BaseIconStyled from '../style';

const SmileIcon = forwardRef(({ children, color, variant, ...props }, ref) => {
  return (
    <BaseIconStyled {...props} fill viewBox="0 0 80 80" color={color}>
      <g clipPath="url(#clip0_105_1899)">
        <path d="M68.2872 68.2847C83.9091 52.6628 83.9091 27.3347 68.2872 11.7128C52.6653 -3.90905 27.3373 -3.90904 11.7154 11.7128C-3.90651 27.3347 -3.90652 52.6628 11.7154 68.2847C27.3373 83.9066 52.6654 83.9066 68.2872 68.2847Z" fill="#EF858C" fillOpacity="0.7"/>
        <path d="M60.928 5.85669C65.8085 13.485 67.9564 22.5434 67.0208 31.5508C66.0851 40.5583 62.1212 48.9818 55.7771 55.4441C49.433 61.9065 41.0841 66.0253 32.0955 67.1271C23.1069 68.2289 14.0103 66.2486 6.29321 61.5099C9.53424 66.5758 13.8756 70.8458 18.9945 74.0024C24.1135 77.1589 29.8781 79.1208 35.8599 79.7422C41.8416 80.3636 47.8865 79.6284 53.5449 77.5913C59.2033 75.5542 64.3296 72.2677 68.5427 67.9761C72.7557 63.6846 75.947 58.4984 77.8792 52.8034C79.8115 47.1083 80.4349 41.0509 79.7032 35.0816C78.9715 29.1124 76.9035 23.3849 73.6529 18.3252C70.4023 13.2654 66.0529 9.00361 60.928 5.85669Z" fill="#F1989E"/>
        <path d="M23.6054 46.0516C23.3888 45.8386 23.1053 45.7069 22.8029 45.6788C22.5004 45.6506 22.1976 45.7278 21.9454 45.8972C21.6933 46.0665 21.5073 46.3178 21.419 46.6084C21.3307 46.899 21.3454 47.2113 21.4607 47.4923C22.9369 51.18 25.4753 54.3461 28.7538 56.5889C32.0323 58.8316 35.9031 60.0499 39.8751 60.0891C43.8472 60.1283 47.7413 58.9867 51.0635 56.8092C54.3856 54.6316 56.986 51.5162 58.5347 47.8583C58.6495 47.5788 58.6649 47.2684 58.5782 46.9789C58.4916 46.6894 58.3081 46.4385 58.0586 46.2681C57.809 46.0977 57.5085 46.0182 57.2073 46.0428C56.9062 46.0675 56.6226 46.1948 56.4041 46.4035C50.5521 52.0772 38.1252 60.1537 23.6054 46.0516Z" fill="#2F2F2F"/>
        <path d="M28.2136 32.8409C28.2136 35.5862 26.8949 37.8107 25.2665 37.8107C23.638 37.8107 22.3193 35.5862 22.3193 32.8409C22.3193 30.0955 23.638 27.8711 25.2665 27.8711C26.8949 27.8711 28.2136 30.0955 28.2136 32.8409Z" fill="#2F2F2F"/>
        <path d="M57.6853 32.8409C57.6853 35.5862 56.3666 37.8107 54.7382 37.8107C53.1097 37.8107 51.791 35.5862 51.791 32.8409C51.791 30.0955 53.1097 27.8711 54.7382 27.8711C56.3666 27.8711 57.6853 30.0955 57.6853 32.8409Z" fill="#2F2F2F"/>
      </g>
      <defs>
        <clipPath id="clip0_105_1899">
          <rect width="80" height="80" fill="white"/>
        </clipPath>
      </defs>
    </BaseIconStyled>
  );
});

export default SmileIcon;
