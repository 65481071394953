import { createReducer, createAction } from '@reduxjs/toolkit';

export const show = createAction('SHOW_LOADING');
export const hide = createAction('HIDE_LOADING');
const initialState = {
  isLoading: false
};
const htmlDom = document.querySelector('html');
const loadingReducer = createReducer(initialState, (buidler) => {
  buidler
    .addCase(show, (state, _) => {
      htmlDom.classList.add('disable-overflow');
      state.isLoading = true;
    })
    .addCase(hide, (state, _) => {
      state.isLoading = false;
      htmlDom.classList.remove('disable-overflow');
    })
});

export default loadingReducer;
