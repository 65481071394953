import { forwardRef } from 'react';
import BaseIconStyled from '../style';

const Question2Icon = forwardRef(({ children, color, variant, ...props }, ref) => {
  return (
    <BaseIconStyled {...props} fill viewBox="0 0 32 32" color={color}>
      <path d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 18.9143 0.779154 21.6466 2.14052 24L0.8 31.2L8 29.8595C10.3534 31.2208 13.0857 32 16 32Z" fill="#EF858C" fillOpacity="0.1"/>
      <path d="M16.8125 23.3516C17.7402 23.3516 18.5801 23.2051 19.3418 22.9316L20.4844 24.4648H22.9453L21.0703 21.9453C22.6035 20.6758 23.4727 18.5957 23.4727 15.959V15.9395C23.4727 11.3984 20.9141 8.55664 16.8125 8.55664C12.7207 8.55664 10.1426 11.3984 10.1426 15.9395V15.959C10.1426 20.4902 12.6816 23.3516 16.8125 23.3516ZM16.8125 21.1738C14.2734 21.1738 12.7109 19.123 12.7109 15.959V15.9395C12.7109 12.7559 14.3125 10.7344 16.8125 10.7344C19.3125 10.7344 20.9043 12.7559 20.9043 15.9395V15.959C20.9043 17.6777 20.4355 19.0645 19.6055 19.9824L18.5703 18.5859H16.1094L17.9258 21.0273C17.5742 21.125 17.2031 21.1738 16.8125 21.1738Z" fill="#EF858C"/>
    </BaseIconStyled>
  );
});

export default Question2Icon;
