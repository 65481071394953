import styled from '@emotion/styled';

export default styled.label((props) => ({
  position: 'relative',
  paddingLeft: props.theme.spacing(8),
  cursor: 'pointer',
  WebkitUserSelect: 'none',
  MozUserSelect: 'none',
  msUserSelect: 'none',
  userSelect: 'none',
  fontSize: 18,
  height: props.height || '24px',
  lineHeight: '24px',
  display: 'inline-flex',
  'input': {
    position: 'absolute',
    opacity: 0,
    cursor: 'pointer',
    height: 0,
    width: 0,
  },
  '.checkmark': {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 24,
    width: 24,
    backgroundColor: 'var(--white-color)',
    border: `1.5px solid var(${props.borderColor})`,
    borderRadius: props.borderRadius
  },
  'input:checked ~ .checkmark': {
    backgroundColor: 'var(--primary-color)',
    borderColor: 'var(--primary-color)'
  },
  'input:checked ~ .checkmark:after': {
    display: 'block'
  },
  '.checkmark:after': {
    content: '""',
    position: 'absolute',
    display: 'none',
    left: 7,
    top: 3,
    width: 6,
    height: 12,
    border: 'solid white',
    borderWidth: '0 2px 2px 0',
    WebkitTransform: 'rotate(45deg)',
    msTransform: 'rotate(45deg)',
    transform: 'rotate(45deg)',
  },
  '.indeterminate': {
    display: 'block',
    backgroundColor: 'var(--primary-color)',
    borderColor: 'var(--primary-color)'
  },
  '.indeterminate:after': {
    borderWidth: 0
  },
  '.checkmark.indeterminate:after': {
    borderWidth: '0 2px 2px 0',
  }
}));
