import { forwardRef } from 'react';
import BaseIconStyled from '../style';

const VideoIcon = forwardRef(({ children, color, variant, ...props }, ref) => {
  return (
    <BaseIconStyled {...props} color={color}>
      <path d="M14.2001 12L10.3501 14.2535V9.74653L14.2001 12Z" />
      <path d="M1 13.5768V10.4232C1 7.53304 1 6.08795 1.90214 5.15713C2.80427 4.22632 4.22957 4.18171 7.08015 4.09249C8.75696 4.04 10.5204 4 12 4C13.4796 4 15.243 4.04 16.9199 4.09249C19.7704 4.18171 21.1957 4.22632 22.0979 5.15713C23 6.08795 23 7.53304 23 10.4232V13.5768C23 16.4669 23 17.912 22.0979 18.8428C21.1957 19.7736 19.7705 19.8182 16.92 19.9075C15.2431 19.96 13.4797 20 12 20C10.5203 20 8.75689 19.96 7.08004 19.9075C4.22951 19.8182 2.80425 19.7736 1.90213 18.8428C1 17.912 1 16.4669 1 13.5768Z" />
    </BaseIconStyled>
  );
});

export default VideoIcon;
