import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import StyledContainer from './style';

const Container = forwardRef(({children, ...props}, ref) => {
  return (
    <StyledContainer {...props}>
      {children}
    </StyledContainer>
  );
});

Container.propTypes = {
  size: PropTypes.oneOf(['largest', 'large', 'small', 'smallest', 'mini']),
};

Container.defaultProps = {
  size: 'smallest',
  bottom: 0
}

export default Container;
