import { configureStore, combineReducers } from '@reduxjs/toolkit';
import breadcrumbsReducer from 'reducers/breadCrumbReducer';
import { authApi } from 'services/auth';
import { videoApi } from 'services/video';
import authReducer from 'reducers/authReducer';
import { questionApi } from 'services/question';
import videoReducer from 'reducers/videoReducer';
import alertReducer from 'reducers/alertReducer';
import { uploadApi } from 'services/upload';
import { eventLogApi } from 'services/eventLog';
import notFoundReducer from 'reducers/notFoundReducer';
import loadingReducer from 'reducers/loadingReducer';
import { notificationApi } from 'services/company/notification';

const rootReducer = combineReducers({
  breadcrumbs: breadcrumbsReducer,
  auth: authReducer,
  video: videoReducer,
  alert: alertReducer,
  notFound: notFoundReducer,
  loading: loadingReducer,
  [authApi.reducerPath]: authApi.reducer,
  [videoApi.reducerPath]: videoApi.reducer,
  [questionApi.reducerPath]: questionApi.reducer,
  [uploadApi.reducerPath]: uploadApi.reducer,
  [eventLogApi.reducerPath]: eventLogApi.reducer,
  [notificationApi.reducerPath]: notificationApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      videoApi.middleware,
      questionApi.middleware,
      uploadApi.middleware,
      eventLogApi.middleware
    )
});
