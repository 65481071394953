export const Theme = {
  breakpoints: {
    largest: 1248,
    large: 1160,
    smartphone: 768,
    small: 708,
    smallest: 640,
  },
  mobileSpacing: 24,
  spacing: (spacing) => spacing * 4,
  flex: (flex) => (flex / 12) * 100,
};
