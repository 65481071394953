import { createReducer, createAction } from '@reduxjs/toolkit';

export const resetVideoAction = createAction('RESET_TRAINING');

const initialState = {
  trainingCategories: null,
};

const videoReducer = createReducer(initialState, (buidler) => {
  buidler
    .addCase(resetVideoAction, () => initialState)
});

export default videoReducer;
