import { forwardRef } from 'react';
import BaseIconStyled from '../style';

const MenuIcon = forwardRef(({ children, color, variant, ...props }, ref) => {
  return (
    <BaseIconStyled {...props} fill color={color}>
      <rect
        x="1"
        y="2"
        width="22"
        height="1.46341"
        rx="0.731707"
      />
      <rect
        x="1"
        y="11.2683"
        width="22"
        height="1.46341"
        rx="0.731707"
      />
      <rect
        x="1"
        y="20.5366"
        width="22"
        height="1.46341"
        rx="0.731707"
      />
    </BaseIconStyled>
  );
});

export default MenuIcon;
