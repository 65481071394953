import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import loadingSelector from 'selectors/loadingSelector';
import {
  StyledLoadingMask, StyledLoadingSpinner
} from './style';

const Loading = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const isLoading = useSelector(loadingSelector);
  return (
    <StyledLoadingMask {...props} show={props.isLoading || isLoading}>
      <StyledLoadingSpinner>
        <svg viewBox="-10, -10, 50, 50">
          <path d="
            M 30 15
            L 28 17
            M 25.61 25.61
            A 15 15, 0, 0, 1, 15 30
            A 15 15, 0, 1, 1, 27.99 7.5
            L 15 15
          "></path>
        </svg>
        <p>{props.textLoading || t('common:loadingText')}</p>
      </StyledLoadingSpinner>
    </StyledLoadingMask>
  )
})

export default Loading;
