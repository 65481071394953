import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import StyledTabs from './style';

const Tabs = forwardRef(({children, onChange, value, ...props}, ref) => {
  return (
    <StyledTabs length={children.length} {...props} ref={ref}>
      {children.map((element, index)=> {
        return (
          <button
            onClick={() => onChange(element.props.value)}
            key={index}
            className={`tab ${element.props.value === value ? 'active' : ''}`}
          >{element.props.title}</button>
        )
      })}
    </StyledTabs>
  );
});

Tabs.propTypes = {
  onChange: PropTypes.func.isRequired
}

export default Tabs;
