import { forwardRef } from 'react';
import StyledCheckbox from './style';

const Checkbox = forwardRef(({children, label, borderColor, borderRadius, isIndeterminate, ...props}, ref) => {
  return (
    <StyledCheckbox borderRadius={borderRadius} borderColor={`--${borderColor}-color`} ref={ref} height={props.height}>
      <input type="checkbox" {...props} />
      <span className={`checkmark ${isIndeterminate ? 'indeterminate' : ''}`}></span>
      {label}
    </StyledCheckbox>
  )
})

Checkbox.defaultProps = {
  borderColor: 'border',
  borderRadius: 'none',
  isIndeterminate: false
}

export default Checkbox;

