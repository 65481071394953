import { forwardRef, useEffect, useState } from 'react';
import {
  StyledAvatarUpload,
  StyledLabelAvatar,
  StyledCirle,
  StyledWrapCircle,
  StyledLoadingBackground,
  StyledProgress,
  StyledEditAvatar,
  StyledDropDown,
  DropdownButton,
} from './style';
import { Avatar, Box } from 'components';
import { EditIcon } from 'components/Icon';
import { useTranslation } from 'react-i18next';


const AvatarUpload = forwardRef(({ onChange, onRemove, src, size, isLoading, isError, ...props }, ref) => {
  const { t } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false)

  const handleChange = (e) =>{
    onChange(e);
  }
  const handleRemove = (e) =>{
    onRemove(e);
    setShowDropdown(false)
  }
  
  const [downloadCount, setDownloadCount] = useState(0);
  const [showProgress, setShownProgress] = useState(false);
  const [offset, setOffset] = useState(0);
  useEffect(() => {
    if (isLoading) setShownProgress(true);
    else {
      setDownloadCount(100);
      setTimeout(() => {
        setShownProgress(false);
        setDownloadCount(0)
      }, 1000)
    }
  }, [isLoading])

  useEffect(() => {
    const circle = document.querySelector('circle');
    const circumference = circle ? circle.r.baseVal.value * 2 * Math.PI : 0;
    if (downloadCount === 100) {
      setOffset(circumference)
    }
    const timer = setTimeout(() => { 
      if (downloadCount < 90 && circle) {
        const offset = (downloadCount + 10) / 100 * circumference;
        setOffset(offset)
        setDownloadCount(downloadCount + 10);
      }
    }, 1000)
    return () => {
      clearTimeout(timer);
    };
  }, [showProgress, downloadCount])

  window.onclick = function(event) {
    if (!event.target.matches('.dropdown-btn')) {
      setShowDropdown(!showDropdown)
    }
  }

  return (
    <>
      <StyledAvatarUpload width={size} height={size}>
        <StyledLabelAvatar htmlFor="file">
          <StyledEditAvatar className={'dropdown-btn'} ref={ref}>
            <EditIcon color='white' width={18} height={18} strokeWidth={2}/>
          </StyledEditAvatar>
          <Avatar src={src} size={size} />
        </StyledLabelAvatar>
        {
          showProgress ? (<>
            <StyledLoadingBackground />
            <StyledWrapCircle width={size + 4} height={size + 4}>
              <StyledCirle offset={offset} color={!isError ? 'success' : 'primary'} fill="transparent" r="50" cx="52" cy="52" />
            </StyledWrapCircle>
            <StyledProgress color={!isError ? 'success' : 'primary'}>{downloadCount}%</StyledProgress>
          </>) : ''
        }
      </StyledAvatarUpload>
      <StyledDropDown style={{ visibility: showDropdown ? 'visible' : 'hidden' }}>
        <Box style={{display: 'block'}}>
          <input style={{ display: 'none' }} type="file" name="file" id="changeAvatar" onChange={handleChange} accept=".png, .jpg, .jpeg" disabled={isLoading}/>
          <DropdownButton variant="outlined" color="primary" className={'dropdown-btn'} onClick={ () => document.getElementById('changeAvatar').click() }>{t('common:avatar:uploadAvatar')}</DropdownButton>
        </Box>
        <Box style={{display: 'block' }}>
          <input style={{ display: 'none' }} type="file" name="file" id="removeAvatar" onChange={handleChange} disabled={isLoading}/>
          <DropdownButton variant="outlined" color="primary" className={'dropdown-btn'} onClick={handleRemove} disabled={src == null}>{t('common:avatar:removeAvatar')}</DropdownButton>
        </Box>
      </StyledDropDown>
    </>
  )
})

export default AvatarUpload;
