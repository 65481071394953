import styled from '@emotion/styled';

const StepWrapper = styled.div(props => ({
  paddingTop: '24px',
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center',
  gap: '30px',
}));

const Step = styled.div((props) => ({
  width: '40px',
  height: '40px',
  lineHeight: '40px',
  textAlign: 'center',
  backgroundColor: props.active ? 'var(--stepper-blue)' : 'white',
  border: props.active ? 'var(--stepper-blue) solid 1px' : 'black solid 1px',
  color: props.active ? 'white' : 'var(--text-color)',
  borderRadius: '50%',
}));

export {
  StepWrapper,
  Step
}
