import { forwardRef } from 'react';
import BaseIconStyled from '../style';

const SadIcon = forwardRef(({ children, color, variant, ...props }, ref) => {
  return (
    <BaseIconStyled {...props} fill viewBox="0 0 80 80" color={color}>
      <g clipPath="url(#clip0_105_1863)">
        <path d="M68.2904 68.2862C83.9123 52.6643 83.9123 27.3362 68.2904 11.7143C52.6685 -3.90759 27.3404 -3.90757 11.7185 11.7143C-3.90335 27.3362 -3.90334 52.6643 11.7185 68.2862C27.3404 83.9081 52.6685 83.9081 68.2904 68.2862Z" fill="#EF858C" fillOpacity="0.7"/>
        <path d="M60.9281 5.86133C65.7869 13.4883 67.9184 22.5365 66.975 31.5304C66.0316 40.5242 62.069 48.9332 55.7332 55.386C49.3975 61.8387 41.0625 65.9546 32.0874 67.0625C23.1123 68.1703 14.0267 66.2048 6.31201 61.4864C9.54787 66.5601 13.8868 70.8383 19.0055 74.0025C24.1242 77.1667 29.8909 79.1352 35.8759 79.7614C41.861 80.3877 47.9102 79.6556 53.5731 77.6195C59.2359 75.5835 64.3665 72.2961 68.5825 68.0022C72.7986 63.7082 75.9916 58.5184 77.9236 52.8192C79.8556 47.12 80.4769 41.0584 79.7412 35.0858C79.0055 29.1132 76.9317 23.3835 73.6744 18.3236C70.4171 13.2636 66.0601 9.00378 60.9281 5.86133V5.86133Z" fill="#F1989E"/>
        <path d="M28.2136 30.2974C28.2136 33.0427 26.8902 35.2625 25.2665 35.2625C23.6428 35.2625 22.3193 33.0427 22.3193 30.2974C22.3193 27.552 23.6381 25.3511 25.2665 25.3511C26.8949 25.3511 28.2136 27.552 28.2136 30.2974Z" fill="#2F2F2F"/>
        <path d="M57.6853 30.2974C57.6853 33.0427 56.3666 35.2625 54.7382 35.2625C53.1097 35.2625 51.791 33.0427 51.791 30.2974C51.791 27.552 53.1097 25.3511 54.7382 25.3511C56.3666 25.3511 57.6853 27.552 57.6853 30.2974Z" fill="#2F2F2F"/>
        <path d="M63.6311 21.9112C63.1994 21.7845 62.7817 21.6672 62.3688 21.5546C61.4724 21.3012 60.623 21.0853 59.8017 20.7802C58.6419 20.3945 57.5151 19.9159 56.4322 19.3489C55.36 18.7827 54.3407 18.1215 53.3865 17.3732C52.3631 16.5501 51.3943 15.6612 50.4863 14.7123C50.4612 14.6882 50.4295 14.6722 50.3952 14.6663C50.3608 14.6604 50.3255 14.665 50.2939 14.6795C50.2619 14.6929 50.2348 14.7159 50.2163 14.7453C50.1979 14.7747 50.1889 14.809 50.1906 14.8437C50.3006 16.3283 50.844 17.748 51.7534 18.9265C52.6501 20.1293 53.8067 21.1143 55.137 21.808C56.4061 22.4714 57.7967 22.8705 59.2245 22.9812H59.3887C60.8515 23.0894 62.318 22.8297 63.6546 22.2257C63.6861 22.2107 63.7124 22.1865 63.7299 22.1564C63.7475 22.1263 63.7556 22.0915 63.7532 22.0567C63.7492 22.0232 63.7355 21.9917 63.7138 21.9658C63.6921 21.94 63.6634 21.921 63.6311 21.9112V21.9112Z" fill="#2F2F2F"/>
        <path d="M16.3734 21.9113L17.6311 21.5546C18.5322 21.3012 19.3769 21.0853 20.1982 20.7803C21.358 20.3946 22.4848 19.9159 23.5677 19.349C24.6408 18.7817 25.6615 18.1205 26.6181 17.3733C27.641 16.5513 28.6083 15.6624 29.5136 14.7124C29.5394 14.6878 29.572 14.6716 29.6071 14.6658C29.6422 14.6599 29.6783 14.6647 29.7107 14.6795C29.7423 14.6935 29.769 14.7166 29.7874 14.7459C29.8058 14.7752 29.815 14.8092 29.8139 14.8438C29.7018 16.3279 29.1587 17.7469 28.2512 18.9266C27.3522 20.1273 26.1961 21.1118 24.8676 21.8081C23.597 22.472 22.2048 22.8712 20.7754 22.9813H20.6111C19.1499 23.0891 17.685 22.8293 16.35 22.2257C16.318 22.2109 16.2911 22.187 16.2728 22.1569C16.2544 22.1268 16.2453 22.092 16.2467 22.0568C16.2517 22.0229 16.2662 21.9912 16.2887 21.9654C16.3112 21.9396 16.3406 21.9208 16.3734 21.9113V21.9113Z" fill="#2F2F2F"/>
        <path d="M48.4451 46.0656C46.0922 44.0617 43.088 42.9869 39.9979 43.0434C36.9361 43.0637 33.9747 44.1375 31.6116 46.0844C30.4571 47.0488 29.5008 48.2282 28.7959 49.5571C28.1065 50.8605 27.7305 52.3067 27.6978 53.7808C27.6977 53.9415 27.748 54.0982 27.8416 54.2289C27.9352 54.3596 28.0674 54.4577 28.2195 54.5095C28.3717 54.5613 28.5363 54.5641 28.6902 54.5175C28.844 54.471 28.9795 54.3775 29.0775 54.25C29.7889 53.3077 30.5922 52.4384 31.4755 51.6549C33.8574 49.6066 36.8893 48.4707 40.0307 48.4496C42.0552 48.4336 44.0523 48.9172 45.8452 49.8575C47.8398 50.9299 49.5673 52.4376 50.8995 54.2688C50.9716 54.3635 51.0646 54.4403 51.1712 54.4932C51.2779 54.5461 51.3952 54.5737 51.5143 54.5739C51.6005 54.5736 51.686 54.5593 51.7677 54.5316C51.9214 54.4784 52.0544 54.3781 52.1479 54.245C52.2414 54.1119 52.2906 53.9528 52.2886 53.7901C52.2557 50.9697 50.815 48.093 48.4451 46.0656Z" fill="#2F2F2F"/>
        <path d="M58.2626 37.8435C46.413 51.3919 47.7317 59.1634 47.7317 59.1634C47.7021 60.5653 47.9527 61.9591 48.4687 63.2629C48.9848 64.5668 49.7559 65.7545 50.7369 66.7565C51.718 67.7584 52.8892 68.5545 54.1818 69.0979C55.4745 69.6414 56.8627 69.9213 58.2649 69.9213C59.6672 69.9213 61.0554 69.6414 62.348 69.0979C63.6407 68.5545 64.8119 67.7584 65.7929 66.7565C66.7739 65.7545 67.5451 64.5668 68.0611 63.2629C68.5772 61.9591 68.8278 60.5653 68.7982 59.1634C68.7982 59.1634 70.1356 51.3919 58.2626 37.8435Z" fill="#A5DDF9"/>
        <path d="M50.4253 66.2076C51.8495 67.7923 53.7209 68.9077 55.7923 69.4065C57.8637 69.9053 60.0377 69.7641 62.0272 69.0014C64.0166 68.2388 65.728 66.8906 66.9352 65.135C68.1424 63.3794 68.7886 61.2989 68.7886 59.1682C68.7886 59.1682 69.3377 55.8832 66.4985 49.9138C64.4102 63.5655 55.8362 66.048 50.4253 66.2076Z" fill="#7FCEF5"/>
      </g>
      <defs>
        <clipPath id="clip0_105_1863">
          <rect width="80" height="80" fill="white"/>
        </clipPath>
      </defs>
    </BaseIconStyled>
  );
});

export default SadIcon;
