import { forwardRef } from 'react';
import BaseIconStyled from '../style';

const QuestionListIcon = forwardRef(({ children, color, variant, ...props }, ref) => {
  return (
    <BaseIconStyled {...props} color={color}>
      <path d="M12 10.7778H15.0556H18.1111" />
      <path d="M12 5.88892H15.0556H18.1111" />
      <path d="M7.11111 15.6667V1.6C7.11111 1.26863 7.37974 1 7.71111 1H22.4C22.7314 1 23 1.26863 23 1.6V19C23 21.2091 21.2092 23 19 23C18.7053 23 18.4073 23 18.1111 23" />
      <path d="M2.99997 15.6667H7.11108H12.6222C12.9536 15.6667 13.2251 15.9321 13.2485 16.2626C13.3721 18.0103 14.0784 23 18.1111 23H7.11108H3.99997C2.34312 23 0.999971 21.6569 0.999971 20V17.6667C0.999971 16.5621 1.8954 15.6667 2.99997 15.6667Z" />
    </BaseIconStyled>
  );
});

export default QuestionListIcon;
