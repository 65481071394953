import { forwardRef } from 'react';
import StyledInput from './style';

const Input = forwardRef((props, ref) => {
  return (
    <StyledInput {...props}  ref={ref} />
  );
});

export default Input;
