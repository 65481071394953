import { forwardRef } from 'react';
import BaseIconStyled from '../style';

const AnswerIcon = forwardRef(({ children, color, variant, ...props }, ref) => {
  return (
    <BaseIconStyled {...props} fill viewBox="0 0 32 32" color={color}>
      <path d="M16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 18.9143 31.2208 21.6466 29.8595 24L31.2 31.2L24 29.8595C21.6466 31.2208 18.9143 32 16 32Z" fill="#EF858C"/>
      <path d="M9.69336 23H12.3301L13.502 19.3574H18.7168L19.8984 23H22.5352L17.5352 8.9082H14.6934L9.69336 23ZM16.0312 11.5254H16.1973L18.0918 17.4141H14.1367L16.0312 11.5254Z" fill="white"/>
    </BaseIconStyled>
  );
});

export default AnswerIcon;
