import { forwardRef } from 'react';
import BaseIconStyled from '../style';

const MedalIcon = forwardRef(({ children, color, variant, fill, ...props }, ref) => {
  return (
    <BaseIconStyled {...props} color={color}>
      <path d="M12.8463 33C7.23846 33 2.69243 28.7019 2.69243 23.4C2.69243 19.6417 4.97676 16.3877 8.30433 14.8116C9.671 14.1643 11.2136 13.8 12.8463 13.8C13.2828 13.8 13.713 13.826 14.135 13.8766C15.0284 13.9836 15.8855 14.2003 16.6908 14.512C20.3925 15.9448 23.0001 19.3845 23.0001 23.4C23.0001 28.7019 18.4541 33 12.8463 33Z" fill={fill}/>
      <path d="M16.6908 14.512L23.0001 1M8.30433 14.8116L1.00012 1M14.135 13.8766L7.49012 1M16.8401 1L15.0801 5M2.69243 23.4C2.69243 28.7019 7.23846 33 12.8463 33C18.4541 33 23.0001 28.7019 23.0001 23.4C23.0001 18.0981 18.4541 13.8 12.8463 13.8C7.23846 13.8 2.69243 18.0981 2.69243 23.4Z"/>
    </BaseIconStyled>
  );
});

const MedalIcon2 = forwardRef(({ children, color, variant, ...props }, ref) => {
  return (
    <BaseIconStyled {...props} color={color} viewBox="0 0 16 24">
      <path d="M10.985 10.2895L15 1M5.64813 10.4955L0.999999 1M9.35858 9.85266L5.13 1M11.08 1L9.96 3.75M2.07692 16.4C2.07692 20.0451 4.96985 23 8.53846 23C12.1071 23 15 20.0451 15 16.4C15 12.7549 12.1071 9.8 8.53846 9.8C4.96985 9.8 2.07692 12.7549 2.07692 16.4Z" />
    </BaseIconStyled>
  );
});
export { MedalIcon, MedalIcon2 };
