import { createReducer, createAction } from '@reduxjs/toolkit';

const redirectNotFound = createAction('REDIRECT_NOT_FOUND');
const resetRedirectNotFound = createAction('RESET_REDIRECT_NOT_FOUND');
const initialState = {
  redirectNotFound: false
};
const notFoundReducer = createReducer(initialState, (buidler) => {
  buidler
    .addCase(redirectNotFound, (state, _) => {
      state.redirectNotFound = true;
    })
    .addCase(resetRedirectNotFound, (state, _) => {
      state.redirectNotFound = false;
    })
});

export default notFoundReducer;
