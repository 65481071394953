import { forwardRef, useEffect } from 'react';
import StyledAlert from './style';
import { useDispatch, useSelector } from 'react-redux';
import alertSelector from 'selectors/alertSelector';
import Typography from 'components/Typography';
import { hideAlert } from 'reducers/alertReducer';

const Alert = forwardRef((props, ref) => {
  const { alert: alertData} = useSelector(alertSelector);
  const dispatch = useDispatch();
  useEffect(
    () => {
      let timer = setTimeout(() => {
        if (alertData?.show) {
          dispatch(hideAlert({ type: alertData.type }));
        }
      }, alertData.duration);
      return () => {
        clearTimeout(timer);
      };
    }, [alertData, dispatch]
  );
  return (
    <StyledAlert color={alertData?.type} show={alertData?.show} {...props} ref={ref}>
      <Typography clamp={3} color="white" breakLine={true}>
        { alertData?.message }
      </Typography>
    </StyledAlert>
  );
});

Alert.defaultProps = {
  left: 0,
};

export default Alert;
