import { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PrevIcon } from 'components/Icon';
import { Box } from 'components';
import { StyledBack, BackLink, BackContainer } from './style';

const Back = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const handleBack = () => {
    if (props.to) {
      navigate(props.to);
    } else if (props.customBack) {
      props.customBack();
    } else {
      navigate(-1);
    }
  }
  return (
    <StyledBack  {...props}>
      <BackContainer size="small">
        <BackLink onClick={handleBack} >
          <Box as={PrevIcon} width={12} height={12} color="primary" mr="8px" />
          {t('common:back')}
        </BackLink>
      </BackContainer>
    </StyledBack>
  );
});

export default Back;