import { forwardRef } from 'react';
import StyledImage from './style';

const Image = forwardRef(({children, height, width, ...props}, ref) => {
  return (
    <StyledImage height={height} width={width} {...props} ref={ref} />
  )
})

export default Image;
