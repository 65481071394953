import { forwardRef } from 'react';
import BaseIconStyled from '../style';

const Next2Icon = forwardRef(({ children, color, variant, ...props }, ref) => {
  return (
    <BaseIconStyled fill {...props} color={color}>
      <path d="M7.84009 7.41L12.4201 12L7.84009 16.59L9.25009 18L15.2501 12L9.25009 6L7.84009 7.41Z"/>
    </BaseIconStyled>
  );
});

export default Next2Icon;
