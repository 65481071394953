import { responsive } from 'themes/style';

export const defaultVariantMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  title: 'h6',
  title2: 'h6',
  description: 'p',
  breadcrumb: 'h2',
  default: 'p',
  caption: 'span',
  caption2: 'span'
}

export const fontSizeMapping = {
  h1: 80,
  h2: 32,
  h3: 28,
  h4: 24,
  h5: 20,
  h6: 18,
  title: 18,
  title2: 16,
  title3: 18,
  description: 18,
  breadcrumb: 32,
  default: 16,
  caption: 14,
  caption2: 12
};

export const colorMapping = {
  default: 'var(--text-color)',
  primary: 'var(--primary-color)',
  error: 'var(--error-color)',
  white: 'var(--white-color)',
  disabled: 'var(--disabled-color)',
  success: 'var(--success-color)',
  black: 'var(--black-color)',
  placeholder: 'var(--placeholder-color)',
  default2: 'var(--text2-color)',
}

export const lineHeightMapping = {
  h1: 80,
  h2: 32,
  h3: 28,
  h4: 24,
  h5: 32,
  h6: 18,
  title: 28,
  title2: 16,
  title3: 18,
  description: 28,
  breadcrumb: 32,
  caption: 18
};

export const spacingMapping = {
  mt: 'marginTop',
  mb: 'marginBottom',
  mr: 'marginRight',
  ml: 'marginLeft',
  pt: 'paddingTop',
  pb: 'paddingBottom',
  pr: 'paddingRight',
  pl: 'paddingLeft',
  bg: 'backgroundColor',
  color: 'color'
};

export const specificMapping = {
  breadcrumb: (props) => ({
    paddingTop: props.theme.spacing(15),
    paddingBottom: props.theme.spacing(12),
    [`${responsive('max', 'smartphone')}`]: {
      paddingTop: props.theme.spacing(10),
      paddingBottom: props.theme.spacing(8),
      fontSize: props.fontSize || 28
    }
  })
};
