import React from 'react';

const useTitle = (title, dependency = []) => {
  React.useEffect(() => {
    const prevTitle = document.title;
    document.title = title + ' | Welvie learning';

    return () => {
      document.title = prevTitle;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependency);
}

export default useTitle;