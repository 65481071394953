import { forwardRef } from 'react';
import BaseIconStyled from '../style';

const TrashIcon = forwardRef(({ children, color, variant, ...props }, ref) => {
  return (
    <BaseIconStyled {...props} color={color}>
      <path d="M20.5553 10.7778V22.4C20.5553 22.7313 20.2867 23 19.9553 23H4.04434C3.71297 23 3.44434 22.7313 3.44434 22.4V10.7778" />
      <path d="M9.55554 18.1111V10.7778" />
      <path d="M14.4442 18.1111V10.7778" />
      <path d="M22.9999 5.88879L16.8888 5.88879M1 5.88879L7.11107 5.88879M7.11107 5.88879V1.59994C7.11107 1.26857 7.3797 0.999938 7.71107 0.999938L16.2888 0.999938C16.6202 0.999938 16.8888 1.26857 16.8888 1.59994V5.88879M7.11107 5.88879L16.8888 5.88879" />
    </BaseIconStyled>
  );
});

export default TrashIcon;