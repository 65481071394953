import React from 'react';
import PropTypes from 'prop-types';

const Tab = ({children, value, title, test, ...props}) => {
  return (
    <span value={value} title={title} {...props}>{children}</span>
  )
}

Tab.propTypes = {
  value: PropTypes.any.isRequired,
  title: PropTypes.node.isRequired
};

export default Tab;
