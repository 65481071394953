import { forwardRef } from 'react';
import { Box } from 'components';

const Divider = forwardRef(({onChange, top, bottom, ...props}, ref) => {
  return (
    <Box
      component="hr"
      bg="border"
      border="none"
      height={1}
      width="100%"
      marginTop={top}
      marginBottom={bottom}
      ref={ref}
      {...props}
    />
  );
});

export default Divider;
