import { forwardRef } from 'react';
import StyledAvatar from './style';
// import NoAvatar from 'assets/images/no_avatar.png';
import NoAvatar2 from 'assets/images/no_avatar2.jpg';
const Avatar = forwardRef(({ src, alt, size, ...props }, ref) => {
  return (
    <StyledAvatar width={size} height={size} src={src || NoAvatar2} alt={alt} {...props}></StyledAvatar>
  )
})

export default Avatar;
