import CryptoJS from 'crypto-js';
const isObjEmpty = (obj) => {
  return Object.keys(obj).length === 0;
}

const createObjPresignedUrl = async (file) => {
  return {
    'file': {
      'filename': file.name,
      'byte_size': file.size,
      'checksum': await getMd5HashFromFile(file),
      'content_type': file.type
    }
  }
}

const getBinaryFile = (file) => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result);
    };
    reader.readAsBinaryString(file)
  }) 
}

const getMd5HashFromFile = async (file) => {
  const binary = await getBinaryFile(file)
  let digest = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(binary));
  return digest.toString(CryptoJS.enc.Base64)
}

const getErrorMessage = (error) => {
  return Array.isArray(error) ? error[0] : error;
}

export {
  isObjEmpty,
  createObjPresignedUrl,
  getBinaryFile,
  getErrorMessage,
}
