import { forwardRef } from 'react';
import BaseIconStyled from '../style';

const PageIcon = forwardRef(({ children, color, variant, ...props }, ref) => {
  return (
    <BaseIconStyled {...props} color={color}>
      <path d="M3 22.4V1.6C3 1.26863 3.26863 1 3.6 1H16.8179C16.9747 1 17.1253 1.06138 17.2374 1.171L20.8195 4.67348C20.9349 4.78637 21 4.94102 21 5.10249V22.4C21 22.7314 20.7314 23 20.4 23H3.6C3.26863 23 3 22.7314 3 22.4Z" />
      <path d="M16.5 4.8V1.3996C16.5 1.17891 16.6789 1 16.8996 1C17.004 1 17.1043 1.04088 17.179 1.11388L20.8835 4.73612C20.958 4.80895 21 4.90872 21 5.0129C21 5.22669 20.8267 5.4 20.6129 5.4H17.1C16.7686 5.4 16.5 5.13137 16.5 4.8Z" />
      <path d="M7.5 9.80005L16.5 9.80005" />
      <path d="M7.5 18.6L16.5 18.6" />
      <path d="M7.5 14.2L12 14.2" />
    </BaseIconStyled>
  );
});

export default PageIcon;