import { forwardRef } from 'react';
import BaseIconStyled from '../style';

const WarningIcon = forwardRef(({ children, color, variant, ...props }, ref) => {
  return (
    <BaseIconStyled {...props} color={color}>
      <path d="M20.5536 21H3.44636C1.91071 21 0.94798 19.341 1.70987 18.0077L10.2635 3.03885C11.0313 1.69522 12.9687 1.69522 13.7365 3.03885L22.2901 18.0077C23.052 19.341 22.0893 21 20.5536 21Z" />
      <path d="M12 9V13" />
      <path d="M12 17.01L12.01 16.9989" />
    </BaseIconStyled>
  );
});

export default WarningIcon;
