import { forwardRef } from 'react';
import BaseIconStyled from '../style';

const SaveIcon = forwardRef(({ children, color, variant, ...props }, ref) => {
  return (
    <BaseIconStyled {...props} color={color}>
      <g clipPath="url(#clip0_105_1108)">
        <path d="M3 1H21V23L12 16.4L3 23V1Z" />
      </g>
      <defs>
        <clipPath id="clip0_105_1108">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </BaseIconStyled>
  );
});

export default SaveIcon;

