import { forwardRef } from 'react';
import StyledCard from './style';

const SimpleCard = forwardRef(({children, shadow, ...props}, ref) => {
  return (
    <StyledCard shadow={shadow} {...props}  ref={ref}>
      {children}
    </StyledCard>
  );
});

SimpleCard.defaultProps = {
  radius: 10,
  shadow: true
};

export default SimpleCard;
