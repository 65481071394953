import { forwardRef } from 'react';
import BaseIconStyled from '../style';

const LogoutIcon = forwardRef(({ children, color, variant, ...props }, ref) => {
  return (
    <BaseIconStyled {...props} color={color}>
      <path d="M11.5555 11.9999H20.1111M20.1111 11.9999L16.4444 15.6666M20.1111 11.9999L16.4444 8.33325" />
      <path d="M20.1111 4.66667V3C20.1111 1.89543 19.2157 1 18.1111 1H5C3.89543 1 3 1.89543 3 3V21C3 22.1046 3.89543 23 5 23H18.1111C19.2157 23 20.1111 22.1046 20.1111 21V19.3333" />
    </BaseIconStyled>
  );
});

export default LogoutIcon;
